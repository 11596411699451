

// Custom mixin to generate container-fluid-left and container-fluid-right (fluid on the left/right and normal max width on the other)
// For each breakpoint, define the maximum width of the container in a media query
@mixin make-container-fluid-side-max-widths($side, $max-widths: $container-max-widths, $breakpoints: $grid-breakpoints) {
  margin-#{$side}: 0;
  padding-#{$side}: 0;

  .row {
    margin-#{$side}: 0;
  }

  .col,
  .col-1,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-10,
  .col-11,
  .col-12,
  .col-auto,
  .col-lg,
  .col-lg-1,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12,
  .col-lg-auto,
  .col-md,
  .col-md-1,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-md-auto,
  .col-sm,
  .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-sm-auto,
  .col-xl,
  .col-xl-1,
  .col-xl-2,
  .col-xl-3,
  .col-xl-4,
  .col-xl-5,
  .col-xl-6,
  .col-xl-7,
  .col-xl-8,
  .col-xl-9,
  .col-xl-10,
  .col-xl-11,
  .col-xl-12,
  .col-xl-auto {
    padding-#{$side}: 0;
  }

  @each $breakpoint,
  $container-max-width in $max-widths {
    @include media-breakpoint-up($breakpoint, $breakpoints) {
      max-width: calc(100% - (100% - #{$container-max-width}) / 2);
    }
  }
}

@mixin calc-background-pos-x($width... ) {
  $max-widths: $container-max-widths;
  $breakpoints: $grid-breakpoints;

  $list-width: ();

  @each $breakpoint,
  $container-max-width in $max-widths {
    @include media-breakpoint-up($breakpoint, $breakpoints) {
      @for $i from 0 to length($width) {
        $list-width: append($list-width, calc(#{nth($width, $i + 1)} * #{$container-max-width} + ((100% - #{$container-max-width}) / 2)), comma);
      }
      background-position-x: $list-width;
      $list-width: ();

    }
  }
}

@mixin default-max-widths($max-widths: $container-max-widths, 
  $breakpoints: $grid-breakpoints) {
  @each $breakpoint, $container-max-width in $max-widths {
    @include media-breakpoint-up($breakpoint, $breakpoints) {
      max-width: $container-max-width;
    }  
  }
}


$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1100px
) ;





