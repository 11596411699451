@import url("https://fonts.googleapis.com/css?family=Roboto Slab:300,400,500,700,900");
@import "../../../node_modules/bootstrap/scss/bootstrap";
@import "./abstracts/variables";


html * {
    font-family: 'Roboto Slab', serif;
}

h1.big-title {
    font-weight: 900;
    font-size: 40px;
    line-height: 111.38%;
    color: black;
}

h2 {
    font-weight: 700;
    font-size: 30px;
    line-height: 150.4%;
    color: black;
}

h3 {
    font-weight: 800;
    font-size: 24px;
    line-height: auto;
    color: #DF8E69;
}

strong {
    color: #DF8E69;
}

.usual-text-and-space {
    font-weight: 400;
    font-size: 18px;
    line-height: 152.9%;
}

.usual-text-and-space.sml {
    font-size: 16px;
}

.usual-text-and-space.hvy {
    font-weight: 500;
}


u {
    font-weight: 300; //underline
}

.bold {
    font-weight: 700; //bold
}

.pb-img {
    border-radius: 30px;
}

.test {
    background-color: red;
    z-index: 10;
}

.adapt-container-size {

    @include make-container();
    @include default-max-widths();

}

//hero
.logo {
    max-width: 50%;
}

.btn-primary {
    border-color: #DF8E69;
    background: #D66627;
    color: white;
    border-radius: 8px;

    font-weight: 700;
    font-size: 17px;
    line-height: 22px;
    text-align: center;
    text-transform: uppercase;
}

.btn-primary:hover {
    border-color: #ab6b4e;
    background: #bf5c23;
}

.btn-primary:active:focus {
    border-color: #DF8E69;
    background: #ae5521;
}

//bullet points
.bullet-point {
    background: url("../img/apple-tree-crop-svgrepo-com.svg"), #70A67913;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center left;
    background-size: auto 90%;
}

//problem
.problem {
    background-image: url("data:image/svg+xml,%3Csvg width='258' height='258' viewBox='0 0 258 258' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='129' cy='129' r='129' fill='%23DF8E69' fill-opacity='0.25'/%3E%3C/svg%3E"), url("data:image/svg+xml,%3Csvg width='148' height='148' viewBox='0 0 148 148' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='74' cy='74' r='74' fill='%23DF8E69' fill-opacity='0.25'/%3E%3C/svg%3E"), url("data:image/svg+xml,%3Csvg width='280' height='280' viewBox='0 0 280 280' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='140' cy='140' r='140' fill='%23DF8E69' fill-opacity='0.07'/%3E%3C/svg%3E");
    background-repeat: no-repeat;

    //placement responsive des bulles en fond
    @include calc-background-pos-x(0.87, 0.7, 0.17);
    background-position-y: 20%, 5%, 80%;
}

.arrow {
    width: 3rem;
    height: 3rem;
}



//advantages
.rectangle-card {

    background: #FFFFFF;
    box-shadow: -10px -10px 5px 1px rgba(0, 0, 0, 0.25);
    border-radius: 33px;
}

.advantages {
    background: linear-gradient(355.12deg, rgba(127, 186, 136, 0.84) 9.59%, rgba(255, 255, 255, 0.84) 85.54%);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.border-top {
    border-width: 16px !important;
    border-color: #638969 !important;
}

.images-section {
    background-color: #638969;
}

//how-it-works section
.how-it-works {
    background-image: url("data:image/svg+xml,%3Csvg width='496' height='458' viewBox='0 0 496 458' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M307.068 23.5418C522.787 87.0118 559.421 308.858 382.462 409.351C205.502 509.844 148.955 425.74 115.499 409.351C82.0435 392.961 -7.14772 220.543 0.794397 112.745C8.73652 4.94801 160.617 -26.6348 307.068 23.5418Z' fill='%23DF8E69' fill-opacity='0.1'/%3E%3C/svg%3E"), url("data:image/svg+xml,%3Csvg width='564' height='422' viewBox='0 0 564 422' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M399.5 323.5C200.056 427.351 -48.8216 489.616 8.99992 294.5C66.8215 99.3843 196.528 0.121337 247.862 0.888658C299.197 1.65598 467.157 51.8853 536.183 135.064C605.209 218.243 539.816 258.102 399.5 323.5Z' fill='%23DF8E69' fill-opacity='0.1'/%3E%3C/svg%3E"), url("data:image/svg+xml,%3Csvg width='498' height='381' viewBox='0 0 498 381' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M234.099 377.857C-1.90108 338.308 -18.0356 231.357 11.5989 131.357C41.2334 31.3574 161 0.49999 200.5 0.499988C240 0.499987 409.295 30.9965 478.321 114.175C547.347 197.354 416.099 408.358 234.099 377.857Z' fill='%23DF8E69' fill-opacity='0.1'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    //placement responsive des bulles en fond
    @include calc-background-pos-x(0.24, 0.75, 0.23);
    background-position-y: 11%, 56%, 98%;

}

.rsp-vd {
    width: 100%;
    height: auto;
    border-radius: 22px;
    overflow: hidden;
    transform: translateZ(0);
    box-shadow: 0 19px 51px 0 rgba(0,0,0,0.16), 0 14px 19px 0 rgba(0,0,0,0.07);
}


//support section
.support {
    background: rgba(223, 142, 105, 0.07);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

}

// More details section
.more-details {
    background-image: url("data:image/svg+xml,%3Csvg width='485' height='485' viewBox='0 0 485 485' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M242.498 485C359.039 485 453.85 390.193 453.85 273.656V235.469C453.85 159.129 391.738 97.0214 315.389 97.0214C297.754 97.0214 280.624 100.303 264.607 106.64C278.221 64.7499 317.908 34.1328 363.874 34.1328C373.301 34.1328 380.94 26.4916 380.94 17.0664C380.94 7.63892 373.301 0 363.874 0C327.511 0 293.158 13.9945 267.142 39.4075C260.381 46.0088 254.399 53.204 249.204 60.8565C230.199 24.7144 192.293 0 148.703 0C139.276 0 131.637 7.63892 131.637 17.0664C131.637 48.2888 144.311 76.6031 164.784 97.142C90.6681 99.6906 31.1473 160.741 31.1473 235.466V273.654C31.1496 390.193 125.959 485 242.498 485ZM225.643 94.0063C197.515 86.8771 175.32 64.6817 168.191 36.554C196.318 43.6809 218.514 65.8763 225.643 94.0063ZM65.2824 235.469C65.2824 177.95 112.076 131.154 169.597 131.154C192.377 131.154 214.038 138.39 232.24 152.078C238.313 156.645 246.678 156.647 252.751 152.08C270.967 138.388 292.627 131.154 315.389 131.154C372.915 131.154 419.718 177.948 419.718 235.469V273.656C419.718 371.37 340.215 450.867 242.498 450.867C144.782 450.867 65.2824 371.37 65.2824 273.656V235.469V235.469Z' fill='%23DF8E69' fill-opacity='0.29'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    //placement responsive des bulles en fond
    @include calc-background-pos-x(0.9);
    background-position-y: 4%;
}


.container {
    position: relative;

}

.rowoverlay {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    bottom: 0;
    right: 0;
    height: 100%;
}

.coloverlay {
    background: #df8e694a;
    height: 85%;
    z-index: 0;
}

.card {
    box-shadow: 9px 11px 24px 12px rgba(0, 0, 0, 0.25);
    border-radius: 21px;

}

.put-front {
    z-index: 2;
}



// about us section
.img-about-us {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 30px;

}

.about-us {

    background-image: url("data:image/svg+xml,%3Csvg width='727' height='908' viewBox='0 0 727 908' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M408.874 529.149C50.6428 357.735 6.10352e-05 0 6.10352e-05 0V908H725.524C725.524 908 767.104 700.564 408.874 529.149Z' fill='%23DF8E69'/%3E%3C/svg%3E");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: left bottom;
    //background-size: auto 90%;
}

.about-us .absolute-center {
    bottom: 18%;
    position: absolute;
}

.footer {
    background: #CC7750;
}

@media (max-width: 1200px) {
    .problem {
        background-position-y: 45%, 23%, 75%;
    }

    .how-it-works {
        background-size: 35%, 35%, 35%;
        background-position-y: 16%, 56%, 98%;
    }

    .about-us .absolute-center {
        bottom: 25%;
    }

}

@media (max-width: 992px) {
    .hero {
        background: url("data:image/svg+xml,%3Csvg width='682' height='775' viewBox='0 0 682 775' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M298.435 451.642C634.492 305.335 682 0 682 0V775H1.3846C1.3846 775 -37.6219 597.948 298.435 451.642Z' fill='%23DF8E69'/%3E%3C/svg%3E"), linear-gradient(309.09deg, rgba(223, 142, 105, 0.5) 22.51%, rgba(255, 255, 255, 0.5) 73.02%);
        background-size: contain;
        background-position: bottom right;
        background-repeat: no-repeat;
        text-align: center;
    }

    .bullet-point svg {
        width: 50%;
        height: 50%;
    }

    .arrow {
        width: 1.7rem;
        height: 1.7rem;
    }

    .problem .b {
        text-align: center;
    }

    .problem {
        background-position-y: 73%, 42%, 20%;
        @include calc-background-pos-x(0.76, 0.63, 0.17);
    }

    .pb-img {
        width: 70%;
    }

    .how-it-works {
        background: none;
    }

    .coloverlay {
        background: none;
    }

    .about-us .absolute-center {
        bottom: 10%;
    }

    .more-details {
        @include calc-background-pos-x(0.9);
        background-position-y: 16%;
    }

}

@media (min-width: 992px) {
    .hero {
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        background: url("data:image/svg+xml,%3Csvg width='682' height='775' viewBox='0 0 682 775' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M298.435 451.642C634.492 305.335 682 0 682 0V775H1.3846C1.3846 775 -37.6219 597.948 298.435 451.642Z' fill='%23DF8E69'/%3E%3C/svg%3E"), linear-gradient(309.09deg, rgba(223, 142, 105, 0.5) 22.51%, rgba(255, 255, 255, 0.5) 73.02%);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: top right;
    }

    .container-fluid-left {
        @include make-container();
        @include make-container-fluid-side-max-widths('left');
    }

    .container-fluid-right {
        @include make-container();
        @include make-container-fluid-side-max-widths('right');
    }

    .white-logo {
        max-width: 70%;
    }

}

@media (max-width: 768px) {
    .problem {
        background-position-y: 79%, 57%, 36%;
        @include calc-background-pos-x(0.73, 0.63, 0.17)
    }

    .problem h2 {
        line-height: 119%;
    }

    .problem .usual-text-and-space {
        line-height: 143%
    }

    .support .img-fluid {
        width: 80%;
    }

    .about-us .absolute-center {
        bottom: 8%;
    }

    .more-details {
        background-position-y: 21%;
    }

}

@media (max-width: 576px) {
    h1.big-title {
        font-size: 30px;
    }

    .symbol {
        width: 50%;
    }

    .arrow {
        width: 1.1rem;
        height: 1.1rem;
    }

    .h3 {
        font-size: 15px;
    }

    .end-pb {
        line-height: 156%;
    }

    .problem {
        background-image: url("data:image/svg+xml,%3Csvg width='258' height='258' viewBox='0 0 258 258' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='129' cy='129' r='129' fill='%23DF8E69' fill-opacity='0.07'/%3E%3C/svg%3E"), url("data:image/svg+xml,%3Csvg width='148' height='148' viewBox='0 0 148 148' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='74' cy='74' r='74' fill='%23DF8E69' fill-opacity='0.07'/%3E%3C/svg%3E"), url("data:image/svg+xml,%3Csvg width='280' height='280' viewBox='0 0 280 280' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='140' cy='140' r='140' fill='%23DF8E69' fill-opacity='0.07'/%3E%3C/svg%3E");
        background-position-x: 87%, 95%, 19%;
        background-position-y: 83%, 50%, 24%;
    }

    .more-details {
        background-image: none;
    }


}

@media (max-width: 510px) {
    .about-us .absolute-center {
        bottom: 5%;
    }
}

@media (max-width: 410px) {
    .symbol {
        width: 100%;
    }


}